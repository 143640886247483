import { Collapse as AntdCollapse } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface CollapseProps {
  collapsible: boolean;
  activeKey?: Array<string | number> | string | number;
  defaultActiveKey?: Array<string | number> | string | number;
  accordion?: boolean;
  destroyInactivePanel?: boolean;
  onChange?: (key: string | string[]) => void;
  style?: React.CSSProperties;
  className?: string;
  iconColor?: string;
}

interface CollapseState {
  uniqueKey: Guid;
}

declare let window: Window & { kuika: any };

class Collapse extends PureComponent<CollapseProps & CommonProps, CollapseState> {
  private memoizedDynamicCssResult = "";

  constructor(props: CollapseProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount() {
    this.setDynamicStyle();
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.children) {
      delete props.children;
    }
    if (props.collapsible) {
      delete props.collapsible;
    }
    return props;
  };

  isCollapsible = () => {
    if (this.props.collapsible == false) {
      return "disabled";
    }
    return undefined;
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    if (this.props.style?.color) {
      result += `.${className.trim()} .ant-collapse-header {
        color: ${this.props.style?.color} !important;
      }`;
      `.${className.trim()} .ant-collapse-arrow {
        font-size: 12px !important;
      }`;
    }
    if (!this.props.style?.textAlign) {
      result += `.${className.trim()} .ant-collapse-header-text {
        flex: unset !important;
      }`;
    } else {
      result += `.${className.trim()} .ant-collapse-header-text {
        text-align: ${this.props.style?.textAlign} !important;
      }`;
    }
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getClassName = () => {
    let result = "";
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result}kcollapse_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  render(): ReactNode {
    return (
      <>
        <AntdCollapse className={this.getClassName()} {...this.getProps()} collapsible={this.isCollapsible()}>
          {this.props.children}
        </AntdCollapse>
      </>
    );
  }
}

const collapse = withCommonEvents(Collapse);
export { collapse as Collapse };

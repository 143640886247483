import { Switch as AntdSwitch } from "antd";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";

interface ToggleProps {
  id?: string;
  value: any;
  onChange: any;
  onClick?: any;
  disabled?: boolean;
  style?: React.CSSProperties;
  defaultValue: any;
}

interface ToggleState {
  curValue: boolean;
}

class Switch extends PureComponent<ToggleProps, ToggleState> {
  constructor(props: ToggleProps) {
    super(props);
    this.state = {
      curValue: this.props.defaultValue ?? this.props.value
    };
  }

  componentDidMount = () => {
    if (this.props.value === null) {
      this.onChange(false);
    }
    this.handleComponentStyling();
  };

  componentDidUpdate = () => {
    if (this.props.value === null) {
      this.onChange(false);
    }
    if (this.props.value !== undefined && this.state.curValue != this.props.value) {
      this.setState({ curValue: this.props.value });
    }
    this.handleComponentStyling();
  };

  handleComponentStyling = () => {
    if (!this.props.style) return;
    const node = ReactDOM.findDOMNode(this) as Element;
    let style: React.CSSProperties = _.clone(this.props.style);

    if (style?.paddingLeft || style?.paddingRight || style?.paddingTop || style?.paddingBottom) {
      const parent = document.createElement("div");
      const parentId = `switch-container${this.props.id}`;
      parent.id = parentId;
      if (node?.parentElement?.id !== parentId) {
        node?.parentElement?.appendChild(parent);
        parent?.appendChild(node);
      }
      parent.style.paddingLeft = `${style.paddingLeft}px`;
      parent.style.paddingTop = `${style.paddingTop}px`;
      parent.style.paddingRight = `${style.paddingRight}px`;
      parent.style.paddingBottom = `${style.paddingBottom}px`;
      parent.style.display = "inline-block";
    }
  };

  getDefaultChecked = () => {
    if (this.props?.defaultValue === undefined) return false;
    if (this.props?.defaultValue === null) return false;
    if (this.props?.defaultValue.toString() === "") return false;
    if (this.props?.defaultValue.toString() === "1") return true;
    if (this.props?.defaultValue.toString() === "0") return false;
    if (this.props?.defaultValue.toString().toLowerCase() === "true") return true;
    if (this.props?.defaultValue.toString().toLowerCase() === "false") return false;

    return false;
  };

  getChecked = () => {
    if (this.props.value === null) return false;
    if (this.props.value?.toString() === "") return false;
    if (this.props.value?.toString() === "1") return true;
    if (this.props.value?.toString() === "0") return false;
    if (this.props.value?.toString().toLowerCase() === "true") return true;
    if (this.props.value?.toString().toLowerCase() === "false") return false;

    return undefined;
  };

  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    this.setState({ curValue: e });
  };

  onClick = (e) => {
    if (this.props.onClick && typeof this.props.onClick === "function") {
      this.props.onClick(e);
    }
  };

  getProps = () => {
    let props: any = {};
    props = _.clone(this.props);
    if (props.style) delete props.style;
    return props;
  };

  getStyle = () => {
    let style: React.CSSProperties = {};
    if (this.props.style) {
      style = _.clone(this.props.style);
    }

    if (!this.state.curValue) {
      style.backgroundColor = "#C1C1C1";
    }

    delete style.paddingLeft;
    delete style.paddingRight;
    delete style.paddingTop;
    delete style.paddingBottom;
    return style;
  };

  render(): ReactNode {
    if (this.props.value === null) {
      this.onChange(false);
    }
    return (
      <>
        <AntdSwitch
          {...this.getProps()}
          style={{ ...this.getStyle() }}
          onChange={this.onChange}
          onClick={this.onClick}
          checked={this.getChecked()}
          defaultChecked={this.getDefaultChecked()}
        />
      </>
    );
  }
}

const switchComponent = withCommonEvents(Switch);
export { switchComponent as Switch };

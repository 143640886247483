import { Input } from "antd";
import { Guid } from "guid-typescript";
import _ from "lodash";
import { PureComponent, ReactNode, TextareaHTMLAttributes } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface TextAreaProps extends TextareaHTMLAttributes<any> {
  value?: string;
  allowClear?: boolean;
  autoSize?: boolean;
  showCount?: boolean;
  formatter: "None" | "Lowercase" | "Uppercase";
  readOnly?: boolean;
  maxLength?: number;
}

interface TextAreaState {
  uniqueKey: Guid;
}

declare let window: Window & { kuika: any };

class TextArea extends PureComponent<TextAreaProps & CommonProps, TextAreaState> {
  private memoizedDynamicCssResult = "";

  constructor(props: TextAreaProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount() {
    this.setDynamicStyle();
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);

    if (props.formatter === "None") {
      style.textTransform = "none";
      props.value = props.value;
    } else if (props.formatter === "Uppercase") {
      style.textTransform = "uppercase";
      props.value = props.value ? props.value.toString().toLocaleUpperCase("TR") : undefined;
    } else if (props.formatter === "Lowercase") {
      style.textTransform = "lowercase";
      props.value = props.value = props.value ? props.value.toString().toLocaleLowerCase("TR") : undefined;
    }

    if (props?.value?.toString && props.maxLength) {
      props.value = props.value.toString().substring(0, props.maxLength);
    }

    if (props.readOnly) {
      props.readOnly = true;
    } else {
      delete props.readOnly;
    }

    props.style = style;

    return props;
  };

  getClassName = () => {
    let result = "";
    if (this.props.className) {
      result = this.props.className;
    }
    if (!this.state.uniqueKey) {
      return result;
    }
    result = `${result} ktextarea_${this.state.uniqueKey.toString().substring(0, 8)}`;
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML}
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";

    const { showCount } = this.props;
    if (showCount) {
      result += `.${className.trim()} textarea.ant-input {
          height: 100%;
        }`;
    }

    if (this.props.allowClear) {
      const height = parseInt(this.props.style?.height?.toString() || "0") - 36;
      const maxHeight = parseInt(this.props.style?.maxHeight?.toString() || "0") - 36;
      const minHeight = parseInt(this.props.style?.minHeight?.toString() || "0") - 36;
      result += `.${className.trim()} {
        ${this.props.style?.height && `height: ${height}px !important`};
        ${this.props.style?.maxHeight && `max-height: ${maxHeight}px !important`};
        ${this.props.style?.minHeight && `min-height: ${minHeight}px !important`};
      }`;
    }
    return result;
  };

  render(): ReactNode {
    return (
      <>
        <Input.TextArea {...this.getProps()} className={this.getClassName()} />
      </>
    );
  }
}

const textArea = withCommonEvents(TextArea);
export { textArea as TextArea };

import { SelectProps } from "antd/lib/select";
import { PureComponent, ReactNode } from "react";
import { KSelectBox } from "../../../kuika";
import { CommonProps } from "../common/common-props";

interface ISingleSelectProps extends SelectProps<any> {
  datatextfield?: string;
  datavaluefield?: any;
  onChange?: (value: any) => void;
  defaultText?: string;
}

interface ISingleSelectState {}

class SingleSelect extends PureComponent<ISingleSelectProps & CommonProps, ISingleSelectState> {
  constructor(props: ISingleSelectProps) {
    super(props);
  }

  render(): ReactNode {
    return (
      <>
        <KSelectBox {...this.props} searchable={false}></KSelectBox>
      </>
    );
  }
}

const singleSelect = SingleSelect;
export { singleSelect as SingleSelect };
